.container {
  width: calc(100% - 48px);
  min-height: 400px;
  color: white;
  margin: 24px;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.cardsBelow {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.block {
  height: 301px;
  position: relative;
  padding: 24px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  font-weight: 300;
}

.block img {
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 40px;
  width: 160px;
  height: 160px;
}

.block a {
  text-align: center;
  max-width: 180px;
  font-family: inherit;
  border: 1px solid white;
  border-radius: 42px;
  color: white;
  background: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  text-wrap: wrap;
  margin: 24px 0;
}

.first {
  background-color: #ea30ab;
}

.second {
  background-color: #f88470;
}

.third {
  background-color: #8230ea;
}

@media (max-width: 1024px) {
  .cardsWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .cardsBelow {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .container img {
    right: -10px;
  }

  .container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .block {
    max-width: 280px;
  }

  .block p {
    font-size: 13px;
  }
  .title {
    font-size: 16px !important;
  }

  .block a {
    align-self: start;
    font-size: 12px;
  }
}
