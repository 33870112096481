.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-radius: 40px;
  background-color: #3048ea;
  padding: 40px 24px;
  overflow: hidden;
}

.wrapper {
  width: calc(100% - 48px);
  color: white;
}

.mainText {
  width: 680px;
  font-size: 44px;
  font-weight: 400;
}

.secondaryText {
  width: 470px;
  opacity: 0.8;
  font-size: 16px;
}

.services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  padding: 24px;
  background-color: white;
  border-radius: 30px;
  width: 572px;
  color: black;
}

.services img {
  width: 24px;
  height: 24px;
}

.servicesMainText {
  font-size: 24px;
  color: #14151f;
}

.service {
  display: flex;
  gap: 14px;
  align-items: center;
}

.service p {
  opacity: 0.8;
  color: #14151f;
}

.button {
  width: 210px;
  background: none;
  text-align: center;
  border-radius: 42px;
  padding: 20px 40px;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
}

.mirasPic {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 580px;
  width: 520px;
}

@media (max-width: 1024px) {
  .mainText {
    width: 100%;
    font-size: 24px;
  }

  .secondaryText {
    width: 100%;
    font-size: 12px;
  }

  .services {
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 12px;
    padding: 24px;
  }

  .servicesMainText {
    font-size: 16px;
  }

  .container {
    height: 920px;
  }

  .mirasPic {
    position: absolute;
    width: 364px;
    height: 382px;
    left: -28px;
    bottom: -12px;
  }

  .button {
    width: 140px;
    align-self: center;
  }
}
