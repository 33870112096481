.section {
  width: calc(100% - 48px);
  margin: 24px;
  margin-top: 50px;
  padding-top: 80px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.wrapper > div:nth-child(1) {
  grid-row: 1;
  grid-column: 1;
}
.wrapper > div:nth-child(2) {
  grid-row: 2;
  grid-column: 1;
}
.wrapper > div:nth-child(3) {
  grid-row: 3;
  grid-column: 1;
}
.wrapper > div:nth-child(4) {
  grid-row: 4;
  grid-column: 1;
}
.wrapper > div:nth-child(5) {
  grid-row: 1;
  grid-column: 2;
}
.wrapper > div:nth-child(6) {
  grid-row: 2;
  grid-column: 2;
}
.wrapper > div:nth-child(7) {
  grid-row: 3;
  grid-column: 2;
}
.wrapper > div:nth-child(8) {
  grid-row: 4;
  grid-column: 2;
}

.mainTitle {
  color: #3048ea;
  font-size: 44px;
  align-self: center;
}

.container {
  max-width: 640px;
  border-radius: 20px;
  padding: 16px 28px;
  color: white;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.description {
  font-size: 1rem;
  margin: 10px 0;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .indent {
    display: flex !important;
    flex-direction: column;
  }
  .container {
    height: 110px;
  }
  .description {
    font-size: 13px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
