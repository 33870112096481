.container {
  flex-direction: column;
  margin-top: 50px;
  padding-top: 80px;
  width: 100%;
  height: 748px;
  background-image: url("../../public/backgrounds/aboutUs.png");
  background-size: 100% 748px;
  background-repeat: no-repeat;
}

.pMobile {
  display: none;
}

.card {
  position: relative;

  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  gap: 14px;
  height: 410px;
  margin: 24px;
  padding: 40px 24px;
  border-radius: 40px;
  background-color: #14151f;
  color: white;
}

.card img {
  width: 840px;
  height: 280px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left a {
  font-family: inherit;
  border: none;
  background-color: white;
  border-radius: 42px;
  color: #14151f;
  cursor: pointer;
  font-size: 16px;
  padding: 14px 24px;
}

.title {
  font-size: 48px;
  font-weight: 400;
  text-wrap: wrap;
  background-size: contain;
}

.right p {
  margin: 0;
}

.miras {
  font-size: 44px;
  width: 150px;
  margin: 0 !important;
  margin-left: 10px !important;
  padding-left: 10px;
  background-color: rgb(69, 69, 255);
}

.agency {
  font-size: 44px;
  margin: 0 !important;
  width: 460px;
  padding-left: 10px;
  background-color: rgb(69, 69, 255);
}

.team {
  gap: 24px;
  margin: 24px;
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.secondaryPic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team p {
  color: #14151f;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
}
.team span {
  font-weight: 600;
  color: #43444c;
  font-size: 16px;
}

.pDesktop {
  margin-top: 24px;
}

@media (max-width: 1024px) {
  .pMobile {
    display: block;
  }
  .pDesktop {
    display: none;
  }
  .right {
    display: none !important;
  }
  .card {
    display: flex !important;
    flex-direction: column;
    overflow: hidden;
  }
  .title {
    font-size: 25px;
  }
  .agency {
    width: 260px;
    padding-left: 10px;
    background-color: rgb(69, 69, 255);
  }
  .left a {
    width: 100%;
    font-size: 14px;
  }
  .left p {
    font-size: 12px;
    margin: 40px 0;
  }
  .miras {
    width: 75px;

    margin-left: 10px !important;
    font-size: 25px !important;
  }
  .agency {
    width: 240px;
    font-size: 25px !important;
  }
  .card img {
    bottom: 0px;
    right: -40px;
    width: 380px;
    height: 170px;
  }

  .team {
    display: flex;
    flex-direction: column;
  }
  .mainImage {
    height: 280px !important;
  }
  .secondaryImage {
    width: 136px !important;
    height: 120px !important;
  }
  .secondaryPic {
    flex-direction: row;
  }
  .container {
    height: 748px;
    background-size: 100% 748px;
  }
  .teamMemberDescription p {
    font-size: 16px;
    margin: 0;
  }
  .teamMemberDescription span {
    font-size: 12px;
  }
}
