.container {
  width: calc(100% - 48px);

  margin: 24px;
  margin-top: 50px;
  padding-top: 80px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  height: 580px;
  width: 100%;
  border-radius: 40px;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.image {
  padding: 40px;
  width: 420px;
  height: 400px;
}

.footer {
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 14px;
  background-color: #3048ea;
  color: white;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  font-size: 24px;
}

.footer span {
  max-width: 250px;
}

.title {
  font-size: 20px;
}

.buttons a {
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
  color: white;
  border: 1px solid white;
  background: none;
  padding: 10px 20px;
  border-radius: 42px;
}

.buttons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .card {
    display: flex;
    flex-direction: column;
    height: 380px;
  }
  .image {
    padding: 14px;
    width: 220px;
    height: 280px;
  }
  .info {
    height: 100%;
  }
  .footer {
    font-size: 12px;
  }
}
